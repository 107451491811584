:root {
	--blue-primary: #46b5e8;
	--grey-primary: #6c757d;
	--grey-secondary: #fafafa;
}

html,
body {
	height: 100%;
}

body {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	/* background-color: white !important; */
	font-family: "Libre Franklin", sans-serif;
}

.form-signin {
	min-width: 330px;
	padding: 5px;
}

.form-signin .form-floating:focus-within {
	z-index: 2;
}

.form-signin input[type="email"] {
	margin-bottom: -1px;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
}

.bg-blue-primary {
	background-color: var(--blue-primary);
}

.bg-grey-primary {
	background-color: var(--grey-primary);
}

.bg-grey-secondary {
	background-color: var(--grey-secondary);
}

.hover-grey-primary:hover {
	background-color: var(--grey-primary) !important;
}

.hover-grey-secondary:hover {
	background-color: #4d4d4d !important;
}
