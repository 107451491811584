:root {
  --color-si: #00335f;
  --color-si-claro: #d6ecff;
  --color-no: #8e111d;
  --color-no-claro: #f9c8cd;
  --color-neutral: rgb(228, 228, 228);
}

.App {
  text-align: center;
}

.modal-backdrop {
  height: 100% !important;
  width: 100% !important;
}
#MainContent {
  box-shadow: 0px 2px 0px 15px rgba(248, 248, 248, 0.75);
  -webkit-box-shadow: 0px 2px 0px 15px rgba(248, 248, 248, 0.75);
  -moz-box-shadow: 0px 2px 0px 15px rgba(248, 248, 248, 0.75);
  margin-top: 5rem;
  border-radius: 1rem 1rem 0rem 0rem;
  min-height: 100vh;
}
#navbarMobile {
  display: none;
}

/* MODIFICACIÓN DE ELEMENTOS DE BOOSTRAP */
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -430% !important;
  margin-top: var(--bs-dropdown-spacer);
}

.breakSpaces {
  white-space: break-spaces !important;
}
@media (max-width: 768px) {
  #navbarMobile {
    display: block;
  }

  #sidebar {
    display: none;
  }
  #MainContent {
    margin-top: 0rem;
    border-radius: 0rem;
    padding-inline: 0rem;
  }
  #navbarPc {
    display: none;
  }
  .cardBorderIndicator {
    display: none;
  }
  .borderLeftResponsive {
    border-left: none !important;
  }
  .paddingLeftResponsive {
    padding-left: 0 !important;
  }
  .marginCards {
    margin-inline: 0 !important;
    padding-inline: 0 !important;
  }
}

#emptyScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
#cerrarSesion {
  position: relative;
  bottom: 0;
  left: 0;
}

.paddingLeftResponsive {
  padding-left: 0.5rem;
}
.borderLeftResponsive {
  border-left: 1px solid #e4e4e4;
}
.justifyText {
  text-align: justify;
}
.bgCeleste50 {
  background-color: rgba(0, 188, 212, 0.15);
}
.gpt3Response {
  background-color: var(--color-neutral) !important;
  color: rgb(92, 92, 92) !important;
}
.gpt3Response-si {
  background-color: var(--color-si-claro) !important;
  color: var(--color-si) !important;
}
.gpt3Response-no {
  background-color: var(--color-no-claro) !important;
  color: var(--color-no) !important;
}

.background-si {
  background-color: var(--color-si) !important;
}
.background-no {
  background-color: var(--color-no) !important;
}

.border-bottom-si {
  border-bottom: 6px solid var(--color-si);
}
.border-bottom-no {
  border-bottom: 6px solid var(--color-no);
}
.border-bottom-neutral {
  border-bottom: 6px solid #6c757d;
}
.resultCard {
  transition: box-shadow 0.3s;
}
.resultCard:hover {
  box-shadow: 2px 2px 2px rgba(85, 85, 85, 0.3);
}
.opcionesUsuario:hover {
  background-color: white !important;
}
.justificarTexto {
  text-align: justify;
}
.footer {
  padding-inline: 50px;
  padding-block: 10px;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 2px solid rgb(242, 242, 242);
  background-image: linear-gradient(to bottom, #ffffff, #fcfcfc, #f9f9f9, #f7f7f7, #f4f4f4);
}
.footer a {
  text-decoration: none;
}
.footer ul li {
  list-style: none;
}

.stikyMain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.bordo {
  background-color: var(--color-no) !important;
}
.fixed {
  position: fixed !important;
}
